// used to safely get a deeply nested value
// eg. getting   object.property.aNestedArray[0].oneMoreThing
// can be a pain in terms of defensiveness
// so we can use: safeGet(object, ['property', 'aNestedArray', 0, 'oneMoreThing'])
// to get the nested value without an error occuring if one of the properties doesn't exist.
// we can also provide a fallback value if we fail to get our deeply nested value.
// future JS syntax proposes: object.property?.thing?.anotherThing type syntax, which could be
// introduced right now using babel if it were agreed.
export const safeGet = (
  object: Object | any[] | undefined,
  pathArray: any[],
  fallback: any = undefined,
) => {
  if (object === 'undefined') {
    return fallback;
  }
  const target = pathArray.reduce(
    (obj, property) => obj && obj[property],
    object,
  );
  return typeof target !== 'undefined' ? target : fallback;
};
